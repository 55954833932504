

























































































































































































































































































































































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import ModuleConfigSettingsWrapper from '@/includes/logic/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/includes/logic/Modules/components/ModuleConfigSettings.vue'
import FacecontrolHandlerModuleBuilder
  from "@/includes/logic/Modules/modules/modules/FacecontrolHandlerModule/FacecontrolHandlerModuleBuilder";
import PunishActionSetting from "../../../PunishActions/PunishActions/actions/components/PunishActionSetting.vue";
import { PunishActionTypesEnum } from "../../../PunishActions/PunishActions";
import { BASE_SUPER_LIST_TYPES } from "@/includes/logic/TypedChipsData";
import Placeholders from "@/mixins/placeholders/placeholders";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import { default as TypedBlackWhiteListInput } from 'piramis-base-components/src/components/Pi/fields/TypedBlackWhiteListInput/TypedBlackWhiteListInput.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';

import { Component, Mixins, Ref, VModel } from 'vue-property-decorator'
import Vue from "vue";

@Component({
  data() {
    return {
      StepUnit,
      BASE_SUPER_LIST_TYPES,
    }
  },
  components: {
    Icon,
    PunishActionSetting,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    NumberInput,
    TimeUnitInput,
    SwitchInput,
    TypedBlackWhiteListInput,
    NestedContent
  }
})
export default class FacecontrolHandlerModuleView extends Mixins(ModuleBindings, InputSetups, Placeholders, TariffsTagsHelper) {
  @VModel() module!: FacecontrolHandlerModuleBuilder

  setRecheckTime(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (isNaN(val)) {
        arg.model[arg.key] = 600
      } else {
        if (val >=600 || val === 0) {
          arg.model[arg.key] = val
        } else {
          arg.model[arg.key] = 600
        }
      }
    }

    return arg
  }

  punishActionTypes: Array<PunishActionTypesEnum> = [
    PunishActionTypesEnum.Notify,
    PunishActionTypesEnum.DeleteMessage,
    PunishActionTypesEnum.MuteMedia,
    PunishActionTypesEnum.Mute,
    PunishActionTypesEnum.Ban,
    PunishActionTypesEnum.Kick,
  ]
}
